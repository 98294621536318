/**
 * BlogListPage template that...
 *
 */
import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import ContentCard from '../components/content-card'
import Pager from '../components/pager'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import useContentData from '../hooks/use-content-data'
import { ContentNode } from '../types/content'

const BlogListPage = ({ data, pageContext, location }) => {
  //console.log('-----BlogListPage: ', data, pageContext, location)
  const blog = data.allMdx.edges
  const { currentPage, numPages } = pageContext
  const pageTitle = 'Fair Open Data Blog'

  return (
    <Layout>
      <Seo title={pageTitle} image={getSrc(data.bannerImage)} />
      <header className="l-header">
        <div className="l-banner-image">
          <GatsbyImage
            imgClassName="banner-image"
            image={getImage(data.bannerImage)}
            alt="Binary white data showing a red heart — free license by Alexander Sinn from Unsplash.com"
          />
        </div>
        <div className="l-banner-overlay"></div>
        <div className="l-banner-content">
          <div className="l-constrained text-light text-shadow">
            <h1 id="page-title" className="page-title">
              {pageTitle}
              {/*               {pageTitle}{' '}
              {currentPage === 1
                ? null
                : ` - Page ${currentPage} of ${numPages}`} */}
            </h1>
            <p className="image-caption">
              Photo by {` `}
              <a
                href="https://unsplash.com/"
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                Alexander Sinn
              </a>
            </p>
          </div>
        </div>
      </header>
      <main className="l-main">
        <div className="l-constrained text-highlight">
          <h2>Recent Posts</h2>
          {blog.map((edge: ContentNode) => {
            const node = useContentData(edge)
            return <ContentCard key={node.id} {...node} />
          })}
          <Pager {...pageContext} />
        </div>
      </main>
    </Layout>
  )
}

export const blogListQuery = graphql`
  query paginatedBlogListQuery($skip: Int!, $limit: Int!, $currentDate: Date!) {
    bannerImage: file(
      relativePath: { eq: "alexander-sinn-KgLtFCgfC28-unsplash.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 90
          formats: [AUTO, WEBP, AVIF]
          breakpoints: [400, 750, 1080, 1366, 1920, 2560]
          placeholder: BLURRED
        )
      }
    }
    allMdx(
      filter: {
        fields: { collection: { eq: "posts" } }
        frontmatter: { published: { eq: true }, date: { lte: $currentDate } }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          excerpt
          timeToRead
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY", locale: "en-CA")
            dateTime: date
            description
            categories
            tags
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
export default BlogListPage
