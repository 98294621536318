/**
 * ContentCard component that...
 *
 */
import * as React from 'react'
import { Link } from 'gatsby'
import Card from './card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faClock } from '@fortawesome/free-regular-svg-icons'
import { ContentData } from '../types/content'

export default function ContentCard(props: ContentData) {
  /* console.log("-----ContentCard: ", props) */
  const displayMeta = props.date || props.dateTime || props.timeToRead || false
  const preview = props.description || props.excerpt
  return (
    <Card key={props.id}>
      <article key={props.id}>
        <h3>
          {props.link != null ? (
            <a
              href={props.link}
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              {props.title}
            </a>
          ) : (
            <Link to={props.slug}>{props.title}</Link>
          )}
        </h3>
        {displayMeta ? (
          <p>
            <span>
              <FontAwesomeIcon
                icon={faCalendarAlt}
                size="1x"
                aria-hidden="true"
              />
              {` `}
              <time date={props.dateTime}>{props.date}</time>
            </span>{' '}
            {props.timeToRead && (
              <span>
                {' '}
                |{' '}
                <FontAwesomeIcon icon={faClock} size="1x" aria-hidden="true" />
                {` `}
                {props.timeToRead} min read
              </span>
            )}
          </p>
        ) : null}
        {preview != null ? <p>{preview}</p> : null}
      </article>
    </Card>
  )
}
