/**
 * Pager component that...
 *
 */
import * as React from 'react'
import { Link } from 'gatsby'
import * as pagerStyles from '../styles/pager.module.scss'

const Pager = (props: PagerProps) => {
  /*   console.log('-----Pager: ', currentPage, numPages, basePath) */
  const isFirst = props.currentPage === 1
  const isLast = props.currentPage === props.numPages
  const prevPage =
    props.currentPage - 1 === 1
      ? `${props.basePath}/`
      : `${props.basePath}/${props.currentPage - 1}/`
  const nextPage = `${props.basePath}/${props.currentPage + 1}/`
  return (
    <div className={pagerStyles.pagerWrapper}>
      {!isFirst ? (
        <Link to={prevPage} rel="prev">
          ← Previous
        </Link>
      ) : (
        ``
      )}
      {!isLast ? (
        <Link to={nextPage} rel="next">
          Next →
        </Link>
      ) : (
        ``
      )}
    </div>
  )
}
interface PagerProps {
  currentPage: number
  numPages: number
  basePath?: string
}
export default Pager
