/**
 * Card wrapper component that...
 *
 */
import * as React from "react"
import * as cardStyles from "../styles/card.module.scss"

interface CardProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any
}
export default function Card(props: CardProps) {
  return <div className={cardStyles.card}>{props.children}</div>
}
