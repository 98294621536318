import { ContentData, ContentNode } from '../types/content'

function useContentData(content: ContentNode): ContentData {
  const data = {
    id: content.node.id,
    excerpt: content.node.excerpt,
    timeToRead: content.node.timeToRead,
    slug: content.node.fields.slug,
    title: content.node.frontmatter.title,
    date: content.node.frontmatter.date,
    dateTime: content.node.frontmatter.dateTime,
    published: content.node.frontmatter.published,
    description: content.node.frontmatter.description,
    categories: content.node.frontmatter.categories,
    tags: content.node.frontmatter.tags,
    author: content.node.frontmatter.author,
    link: content.node.frontmatter.link,
    toc: content.node.frontmatter.toc
  }
  return data
}
export default useContentData
